.testName img{
    display: inline-block;
    margin: 0px 20px 20px 0px;
    float: left;
}
  
.card-body .decp{
    display: inline-block;
}

/* User Profile sidebar */
.profile-sidebar {
    padding: 20px 0 10px 0;
    background: #fff;
}

.profile-userpic{
    position: relative;
    width: 125px;
    height: 125px;
    text-align: center;
    border: 1px solid #f8f7f7;
    border-radius: 50%;
    margin: 20px auto 0 auto;
    background-color: #f8f7f7 !important;
    display: block;
}

.imgMsg{
    max-width: 149px;
    margin: 0 auto;
    height: 33px;
}

.imgMsg .successMessage .successMsg {
    font-size: 12px !important;;
    color: #fff !important;
    line-height: 10px !important;
}

.profile-usertitle {
    text-align: center;
    margin-top: 20px;
}
  
.profile-usertitle-name {
    color: #5a7391;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
}
  
.profile-usertitle-job {
    text-transform: uppercase;
    color: #5b9bd1;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 15px;
}
  
.profile-userbuttons {
    text-align: center;
    margin-top: 10px;
}
  
.profile-userbuttons .btn {
    font-size: 11px;
    font-weight: 600;
    padding: 6px 15px;
    margin-right: 5px;
}
   
.profileDetails{
    font-size: 18px;
}

.totalPurchase .card-header{
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
}
  
.totalPurchase h1{
    font-size: 34px;
    margin-top: 20px;
}
  
.profileDetails .text-muted{
    padding: 10px;
    font-size: 16px;
    border: 1px solid #f3f3f3;
    max-width: 550px;
}
  
.testName .text-muted{
    color: #212529 !important;
}

.profileDetails .text-muted span{
    max-width: 110px;
    width: 100%;
    display: inline-block;
}

.profile-userpic .btn-file .menu-icon{
    font-size: 4rem;
    padding-top: 10px;
    color: #564f4f;
  }
  
//Upload Profile Pic
  
.profile-userpic img {
    object-fit: cover;
    width: 100%;
    border-radius: 50%;
    height: 125px;
}

.profile-userpic .btn-file .mdi-upload:before{
    padding-top: 13px;
}

.profile-userpic .btn-file .mdi-camera{
    font-size: 15px;
    padding-top: 9px;
    color: #fff;
    position: absolute;
    bottom: -9px;
    background: #4d83ff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 14px;
    cursor: pointer;
}

.btn-file {
    position: absolute;    
    border-radius: 50%;
    z-index: 999;
    bottom: 7px;
    right: 33px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    cursor: pointer;
}

.profile-userpic {
    &:hover{
        .btn-file{
            visibility: visible;
            opacity: 1;
            transition: 0.5s;
        }
    }
}

.btn-file input[type=file] {
    position: absolute;
    right: -13px;     
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;   
}

.loader-div{
    position: relative;
}

.profile-loader{
    z-index: 999;
    left: 50%;
    top: 50%;
    position: absolute;
}

//Modal popup in user details
.modaltextInfo .text-center{
    text-align: left!important;
    font-size: 18px;
    color: #274280;
}
     
.modaltextInfo img{
    width: 100px;
    height: 100px;    
    border: 2pxsolid#274280;
}
     
.modaltextInfo p{
    margin: 8px0;
    font-size: 13px;
}

.btn-model-close{
    position: relative;
    left: 13rem;
    top: -2.5rem;
}

.user-content{
    height: 400px;
    overflow-y: auto;
}

.justify-content-start{
    margin: 0 auto;
}

.content-body .emailText{
    font-size: 12px; 
}
 
.content-body{
    line-height: 24px;
    font-size: 12px;
    font-weight: bold;
}

.checkbox-input .form-check-input{
    margin-top: -3px;
}

.input-box{
    position: relative;
}

.invite-seprator{
    height: 1px;
	width: 64%;
	background: #e7eaed;
}

.input-box .form-group{
    width: 100%;
    input{
        width: 64%;
        margin: 10px 0px;
    }
}

.input-box .invite-btn{
    position: absolute;
    top: 28px;
    right: 1px;
}

.input-box .form-control{
    border: 1px solid #a8a8a9 !important;
    border-radius: 0px;
}

.largerCheckbox{
    width: 18px;
    height: 18px;
    margin-top: -10px !important;
}

.lead label {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    color: #3e6d08;
}

.modal-dialog{
    max-width: 600px;
}

.modal-cancelled{
    max-width: 700px;
}

.checkbox-text{
    margin-top: -21px;
}

.existing-user{
    margin: 10px 0;
	font-size: 20px;
	color: #000;
}

.existing-user-label{
    color: #585a5d !important;
	font-weight: 700;
}

.new-user{
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    text-align: left;
    text-transform: capitalize;
}

.modal-header{
    padding: 12px !important;
    margin-bottom: 0 !important;
}

.profile-name{
    width: 100%;
}

.profile-name h3{
    font-size: 18px;
}

.note-msg{
    margin-top: -34px;
    font-size: 14px;
    padding: 0;
    margin-bottom: 8px !important;
}

//Push To Shopify Popup CSS
.leadershipcontainer {
    position: relative;
}

.secondSection .form-group{ 
    margin-bottom: 0px !important;
}
   
.Profiledescription .form-group{
    margin-bottom: 0px !important;
}

.leadership-popup h1 {
	text-align: center;
	margin: 0;	 
	padding: 10px;
    font-size: 1.5rem;
    // position: relative;
    // top: -38px;
}

.userProfileContainer {
	display: flex;
}

.pushbtn {	
    text-align: center;
}

.pushbtn .btn-sm {
	padding: 12px 30px;
	color: #fff;
	background-color: #4d83ff;
	border-color: #4d83ff;
	border-radius: 0.1875rem;
	border: 1px;
	cursor: pointer;
	font-size: 14px;
}

.pushbtn .deleteBtn{
	background-color: #f35e46;
	border-color: #f35e46;
}

.userProfileContainer .profileImg {
	margin-right: 20px;
}

.userProfileContainer .profileImg img {
	max-width: 140px;
	height: 150px;
	border: 1px solid #eae2e2;
	padding: 5px;
}

.userProfileContainer p {
	margin: 0;
	padding: 0;
	font-size: 14px;
	line-height: 20px;
	text-align: justify;
}

.leadership-popup label{
    color: #686868;
	line-height: 1.4rem;	 
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
}
/* Full-width input fields */
.leadership-popup input[type=text],
.leadership-popup input[type=number], .leadership-popup textarea {
	width: 100%;
    height: 2.25rem;
    padding: 0 5px;
	margin: 5px 0 22px 0;
	display: inline-block;
	font-weight: 400;
    font-size: 0.875rem;
    border: 1px solid #efeeee;
}

.leadership-popup textarea{
    width: 430px;
    height: 123px;
    margin-bottom: 10px;
    resize: none;
    font-size: 12px;
    line-height: 20px;
}

.pushbtn .btn{
    padding: 6px 10px
}

.leadership-popup input[type=text]:focus,
.leadership-popup input[type=password]:focus, .leadership-popup textarea:focus{ 
	outline: 0;
}

.viewUserPopup{
    font-weight: 400;
}

.select-country select{
    width: 100%;
    padding: .4375rem .75rem;
	border: 0;
	outline: 1px solid #c3c1c1;
    color: #797373;
    background: transparent;
}

.edit-profilebtn{
    margin-top: 20px;
}

.name-container{
    display: flex;
    width: 64%;
}

.name-container{
    .first-name{
        margin-right: 4px !important;
    }
    .last-name{
        margin-left: 4px !important;
    }
}

.email-container{
    margin-top: 2px !important;
}

.view-invitee-list{
    display: block;
    text-align: right;
}