.dashboardLt .card .card-body{
    padding: 1.25rem;
    text-align: center;
}
  
.dashboardLt .card .card-body h2{
    color: #fa66b8;
}

.customBtnuser .dropdown .btn{
    border: 1px solid #4d83ff;
    background-color: #4d83ff;
    width: 136px;
    text-align: left;
    position: relative;
}
   
.customBtnuser .dropdown-menu{
    padding: 0px;
}

.customBtnuser .dropdown .dropdown-menu{
    margin-top: 2px;
}

.customBtnuser .dropdown .dropdown-menu .dropdown-item{
    padding: 10px 20px;
}
  
.customBtnuser .dropdown .dropdown-toggle:after{
    position: absolute;
    right: 10px;
}

.totalProfile .card-title{
    color: #4a4a4a;
    font-weight: normal;
}
   
.totalAssignee .card-title{
    color: #4a4a4a;
    font-weight: normal;
}

.totalAssignee{ 
    justify-content: center;
    align-items: center;
}
   
.profilePurchased .card-title{
    color: #4a4a4a;
    font-weight: normal;
}

.selectUsercombo{
    float: right;
}

.choose-profile{
    margin: 10px;
}

.choose-profile label{
    font-weight: 500;
    font-size: 16px;
}

.selectUsercombo select.form-control{
    color: #726d6d;
	border: 1px solid;
    outline: none;
    height: 37px;
}

.customBtnuser{
    display: flow-root;
}

.customer-graph{
    padding: 32px 0;
    background-color: white;
}

.profile-purchased-graph{
    background-color: white;
}

.profile-text{
    padding-top: 30%;
}