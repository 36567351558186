.errorMsg{
    color: red;
}
.successMsg{
    color: green;
}
.imgError{
    text-align: center;
}

.errorMessage, .successMessage{
    padding: 10px;
    margin: 0 20px;
    background: #f35e46;
    color: #fffdfd;
    text-align: left !important;
    font-size: 12px;
}

.successMessage{
    background: #38c738;
}

.errorMessage .errorMsg{
    font-size: 14px;
    color: #fff!important;
}

.successMessage .successMsg{
    font-size: 14px;
    color: #fff!important;
}

.errorNote{
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}

.search-btn{
    position: relative;
    top: 14px;
    right: 25px;
}

.test-btn :hover{
    cursor: not-allowed;
}

.login-heading{
    font-size: 1.23rem;
}

.cancel-success-msg{
    margin-bottom: 12px;
}