.accordion > .card {
    overflow: hidden;
}
  
.accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
  
.accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
  
.accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
}
  
.accordion.accordion-solid-header .card .card-header a[aria-expanded="true"]:before {
    content: "\F143";
    color: #000;
    border: 1px solid #000;
}

.accordion.accordion-solid-header .card .card-header a:before {
    top: 10px;
    right: 8px;
}
  
.accordion.accordion-solid-header .card .card-header a[aria-expanded="false"]:before {
    content: "\F140";
    color: #000;
    border: 1px solid #000;
}
  
.accordion .card .card-header a:before {
    font-family: "Material Design Icons";
    position: absolute;
    right: 7px;
    top: 0;
    font-size: 18px;
    display: block;
}
  
.accordion-solid-header a{
    color:#000;
}
  
.accordion-solid-header a:hover{
    text-decoration: none;
}
  
.card-body ol li .curtResult{
    font-weight: bold;
}
   
.resultListing .card-header {
    background: #6493ff;
    color: #fff;
}

.card-body ol { 
    font-weight: bold;
}
.card-body li span { 
    font-weight: normal;
    max-width: 870px; 
    display: inline-flex; 
}

// Questionire Page Css

.questionAnswerRow ol li{   
    width: 100%;
    padding: 2px;
    border-top: 1px solid lightgray;
    height: 40px !important;
    margin-bottom: 10px;
    line-height: 42px;
}

// .questionAnswerRow ol li:first-child {
//     border-top: 1px solid lightgray;
//     height: 46px !important;
//     line-height: 46px;  
// }

.ansType li{
  margin: 15px 0 24px 0;
  border:1px solid #807a7a;
  width:100%
}

ol li input{
	float:right;
}

.quesAnsList li span { 
  font-weight: normal; 
  max-width: 870px;
  display: inline-flex; 
}

.quesAnsList input[type=number]{
  width: 40px;
  height: 33px;
  padding: 0px;
  text-align:center;
  border: 1px solid #807a7a;
  margin-top: 6px;
}

.questionAnswerRow{
  display: none !important;
}

.active{
	display:block !important;
}

.finishQuestion{
	display:none !important;
}
 
.pull-left{
	float:left;
}

.leadershipPs h3 span {
	color: #3155a7;
}

 .leadershipPs .btn-success{
	max-width:150px;
	width:100%;
	padding:15px 0;
	font-size:1.5rem;
	letter-spacing:1px;
}

.nxthr{
    border-top: 1px solid lightgray;
	padding-top: 10px;
	margin-left: 16px;
	width: 99%;
}
.card .card-title {
    color: #4a4a4a;
    text-transform: uppercase;
    font-size: 24px!important;
    font-weight: 600;
}

.totalQuestion{
    border-bottom:3px solid #b5aeac; 
    margin-bottom: 20px;
}

.totalQuestion h4{
  color: #3155a7;
  font-size: 22px;
}

//View Report css
.userReport select.form-control, .dataTables_wrapper select {
    padding: .4375rem .75rem;
    border: 0;
    outline: 1px solid #c3c1c1;
    color: #797373;
}

//test attempt page
.response{
    text-align: center;
    padding: 80px 0 !important;
    h3{
        i{
            color: green;
            font-size: 9rem;
        }
        font-size: 2.5rem;
        font-weight: 400;
    }
    p{
        margin-top: 2rem;
        font-size: 1.5rem;
        margin-left: 0rem;
    }
}

//View Test 
.containerWidth{
    width:94%;
}
.question-span{
    line-height: 20px;
}


//View Report
.graph{
    text-align: center;
    position: relative;
}
.profile-graph{
    position: absolute;
    width: 100%;
    height: 20px;
    background-color: white;
    bottom: -2px;
}
.graphList{
    border:1px solid #999;
    height: 30px;
    max-width: 500px;
    margin: 0 auto;
}
   
.graphList ul{
    display: flex;
    list-style: none;
}
   
.graphList ul li{
    font-size: 14px;
    line-height: 30px;
    margin: 0 20px;
    font-weight: 600;
}
   
.cl1{
    height: 10px;
    width: 10px;
    background-color: #3155a7;
    border-radius: 50%;
}
   
.cl2{
    height: 10px;
    width: 10px;
    background-color: #c3512f;
    border-radius: 50%;
}
   
.cl3{
    height: 10px;
    width: 10px;
    background-color: #fa66b8;
    border-radius: 50%;
}
   
.cl4{
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
}
   
#myChart-license-text{
    display: none !important;
} 
   
#myChart {
    margin-left: 10%;
    height: 100%;
    min-height: 450px;
    margin-top: -75px;
    background: transparent;
}
     
.zc-ref {
    display: none;
}
     
.controls {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 10%;
    background-color: #9e9e9e;
    padding: 15px;
}
     
.controls label {
    width: 75px;
    display: inline-block;
}
     
.controls input {
    margin-bottom: 5px;
    width: 90%;
}

.test-profile-name {
    font-size: 20px;
    color: #2b67ca;
    position: relative;
    text-align: right;
    top: -54px;
    height: 0px;
}

.test-profile-name h2{
    font-size: 23px;
}

a:hover {
    text-decoration: none !important;
}

.guideline-note-section{
    font-size: 0.875rem;
    line-height: 20px;
}

.print-guideline-note-section{
    font-size: 12px;
    line-height: 18px;
}

//Graph Css
a.canvasjs-chart-credit {
    display: none;
}

.test-error-msg{
    position: relative;
    /* right: 7px; */
    /* top: -11px; */
    /* width: 468px; */
    /* background: red; */
    color: #f91414;
    /* border-radius: 14px; */
    font-size: 14px;
    /* line-height: 28px; */
    /* padding: 0 5px; */
    text-align: right;
    /* height: 14px; */
    font-weight: 500;
}

.test-question{
    width: 100%;
}

.highcharts-title{
    color: #4a4a4a !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    font-family: "Roboto", sans-serif !important;
}

.print-report-btn{
    text-align: right;
    margin-bottom: 20px;
}

.user-report-graph{
    background: #fff;
    max-width: 98%;
    margin: 0 10px;
    padding: 25px 0
}

//Balanced Table CSS
.rotate-text{
    transform: rotate(-90deg);
    position: relative;     
    display: flex;
    font-weight: 700;
    font-size: 1.80rem;
    width: 25px;
    margin-top: 220px;
    letter-spacing: 2px;
}

.balanced-table th, .balanced-table td{    
    border: 1px solid #353232;
}

.balanced-table{
    .th, td{
        padding: 8px;
        white-space: unset;
        line-height: 18px;
    }
}

.plp-heading{
    font-size: 16px;
}

.communication-table{
    .table th, .table td{
        padding: 9px;
        white-space: unset;
    }
}
   
.communication-table{
    .table-bordered th, .table-bordered td{    
        border: 1px solid #353232;
    }
}
   
.overflow-y{
    overflow-y: hidden;
}
   
.border-remove{
    border-top: 1px solid #fff !important;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    padding: 10px 0;
}

.border-remove-inner{
    border-top: 0px !important;
    border-right: 0px !important;
}
   
.border-remove-inner-last{
    border-top: 0px !important;
    border-left: 0px !important;
}
      
.end-heading{
    font-size:17px;
    font-style:italic;
}

.end-pdf-heading{
    // position: relative;
    // left: 8rem;
}
   
.maintable{
    table-layout: fixed;
    width: 536px;
    margin: auto;
    position: relative;
}
   
.td-width .text-left{
    height: 250px;
    width: 230px;
}
   
.table-right-col{
    position: relative;
	bottom: -123px;
	height: 220px;
	width: 243px;
	margin-left: 20px;
}
   
.incline-line{
    width: 570px;
	top: 163px;
	left: -17px;
	margin: auto;
	transform: rotate(-20deg);
	 -o-transform: rotate(45deg);
	 -moz-transform: rotate(45deg);
	-webkit-transform: rotate(-20deg);
	background: #333;
	height: 1px;
	position: absolute;
}
   
/* pdf */
.pdf-table{
    table-layout: fixed;
    width: 100%;
    margin: auto;
}
   
.pdf-left-col-width{
    height: 220px;
    width: 350px;
    margin: 10px;
}
   
.pdf-right-col-width{
    position: relative;    
    bottom: -142px;
    height: 220px;
    width: 366px;
    margin-left: 155px;
}
   
.pdf-line{
    width: 101%;
    top: 170px;
    left: -5px;
    background: black !important;
    margin: auto;
    transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -webkit-transform: rotate(-8deg);
    height: 1px;
    position: relative;
    overflow: hidden;
}

.add-scroll {
	overflow-y:hidden;
}

.add-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.add-scroll::-webkit-scrollbar:horizontal {
    width: 2px;
}

.add-scroll::-webkit-scrollbar:vertical {
    height: 2px;
}

.add-scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: #d6d0d0;
}